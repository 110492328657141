import logo from './topforex-logo-white-blue.png';
import './App.css';
import ContactForm from './components/ContactForm';

function App() {
  return (
      <div>
          <div className="nav">
              <div className="container">
                  <img src={logo} className="" alt="logo"/>
              </div>
          </div>
          <div className="container">
          <div className="notice">
                <h1>Important Notice</h1>
              <p>Please note that <span>Goldenburg Group Limited</span> no longer offers its services on this brand.</p>
              <p>However, you can use the services of Top Trader by visiting the website <a href="https://toptrader.eu/">https://toptrader.eu/</a></p>
          </div>
          <ContactForm />
          </div>
      </div>
  );
}

export default App;
