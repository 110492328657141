import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

export default function ContactForm() {
  const validationErrorMessage =
    "All fields are required. Make sure you have entered the correct information.";

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const [errorMessage, setErrorMessage] = useState(validationErrorMessage);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submitForm = async (event) => {
    setIsSubmitted(true);
    event.preventDefault();
    // Check if form is valid
    const { name, surname, phone, email, subject, message } = formData;

    if (!name || !surname || !phone || !email || !subject || !message) {
      setErrorMessage("Please fill in all the fields");
      setIsInvalid(true);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendgrid/send-mail`,
        {
          name,
          surname,
          phone,
          email,
          subject,
          message,
          toMail: process.env.REACT_APP_SEND_MAIL,
        },
        {
          headers: {
            "private-app-key": process.env.REACT_APP_PRIVATE_APP_KEY,
          },
        }
      );

      if (response?.data?.success) {
        setIsInvalid(false);
        setEmailSend(true);
        setFormData({
          name: "",
          surname: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
          toMail: "",
        });

        return;
      }

      throw Error(
        "Something happened in setting up the request that triggered an Error."
      );
    } catch (error) {
      // Log error message
      if (error?.response) {
        // Get the first key in the error object
        const firstErrorField = Object.keys(error.response.data.error)[0];

        // Get the first error message for that field
        const firstErrorMessage = error.response.data.error[firstErrorField][0];

        setErrorMessage(firstErrorMessage);
        setIsInvalid(true);
      } else {
        setErrorMessage(
          "Something happened in setting up the request that triggered an Error."
        );
        setIsInvalid(true);
      }
    }
  };

  const validateForm = () => {
    return (
      formData.name &&
      formData.surname &&
      formData.phone &&
      formData.email &&
      formData.subject &&
      formData.message
    );
  };

  return (
    <div className="form-wrapper">
      <form className="contact-form" onSubmit={submitForm}>
        <div className="contact-form--two-inputs">
          <div className="w-100 form-input">
            <input
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>
          <div className="w-100 form-input">
            <input
              type="text"
              placeholder="Surname"
              value={formData.surname}
              onChange={handleInputChange}
              name="surname"
            />
          </div>
        </div>

        <div className="contact-form--two-inputs">
          <div className="w-100 form-input">
            <input
              type="text"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleInputChange}
              name="phone"
            />
          </div>

          <div className="w-100 form-input">
            <input
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              name="email"
            />
          </div>
        </div>

        <div className="w-100 form-input">
          <input
            type="text"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleInputChange}
            name="subject"
          />
        </div>

        <div className="w-100 form-input">
          <textarea
            name="message"
            cols="40"
            rows="5"
            placeholder="Message"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <ReCAPTCHA
          sitekey="6Leli74kAAAAAHsfGbHNbQskv2Q07RDKJL0ItUOV"
          size="invisible"
          onChange={(response) => setRecaptchaResponse(response)}
        />
        <div>
          <button
            type="submit"
            className={`btn ${
              !validateForm() || emailSend ? "cursor-not-allowed" : ""
            }`}
          >
            Submit
          </button>
        </div>
        {/* Messages */}
        {isInvalid && isSubmitted && (
          <div className="alert alert__error">{errorMessage}</div>
        )}
        {emailSend && (
          <div className="alert alert__success">Thank you for contact us!</div>
        )}
      </form>
    </div>
  );
}
